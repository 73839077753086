/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useState } from 'react'
import ProductGalleryV2 from 'src/components/sections/ProductGalleryV2'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'
import Breadcrumb from 'src/components/ui/Breadcrumb'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
> & { slug: string }

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: { site },
  } = props

  const searchParams = useSearchParams(props.location)
  const title = 'Search Results | FastStore'
  const EVENT_API_BASE_PATH = 'https://sp.vtex.com'

  if (!searchParams) {
    return null
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        language="pt-br"
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
        linkTags={[
          {
            rel: 'preconnect',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'preconnect',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
          {
            rel: 'dns-prefetch',
            href: EVENT_API_BASE_PATH,
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonproqa.vteximg.com.br',
          } as any,
          {
            rel: 'dns-prefetch',
            href: 'https://decathlonstore.vtexassets.com',
          } as any,
        ]}
      />

      <SROnly as="h1" text={title} />
      <div className="flex items-center max-w-[1344px] w-full my-0 mx-auto px-4 mb-6">
        <Breadcrumb
          type="single"
          breadcrumbList={[
            {
              item: `/marketplace/${props.slug}`,
              name: props.slug ?? '',
              position: 1,
            },
          ]}
        />
      </div>

      <ProductGalleryV2
        title={`Marketplace ${props.slug}`}
        searchEngine="vtex"
        type="seller"
        sellerName={props.slug}
        typeValidation="bySellerName"
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SellerSearchPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'
export default mark(Page)
